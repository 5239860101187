export default [
  {
    id: 0,
    name: "GPT-J",
    type: "English Generate Models",
    info: "The model consists of 28 layers with a model dimension of 4096, and a feedforward dimension of 16384. The model dimension is split into 16 heads, each with a dimension of 256. Rotary position encodings (RoPE) was applied to 64 dimensions of each head. The model is trained with a tokenization vocabulary of 50257, using the same set of BPEs as GPT-2/GPT-3.",
    inputParams: [{ name: "prompt", default: "Love is a Crime" }],
    endpoint:
      "https://gptj-api.dev-metaverse.fun/v1/models/kfserving-custom-model:predict",
    options: { seq_length: { min: 1, max: 512, default: 50, type: "int" } },
  },
  {
    id: 1,
    name: "RPM",
    type: "Chinese Generate Models",
    inputParams: [
      {
        name: "prompt",
        default:
          "李逍遥，是李三思的儿子，李寒空的后人；出生于盛渔村，在李家客栈当店小二。李逍遥原是一乡下客栈店小二，天资聪颖，因一壶酒被酒剑仙传授了蜀山仙剑派剑术，在仙灵岛与赵灵儿相遇，自此经历重重磨难成长为一代旷世奇侠。悟性极高，聪明绝顶，富有强烈的正义感。少年时略滑头带点鬼灵精 。即使是小渔村中的店小二，但在客栈中英雄好汉见得多了。李逍遥的梦想就是成为“大侠”，在他心目中，侠就是像父亲李三思那样仗剑江湖、惩恶扬善，闯出一番声名，令世人尊敬的人。",
      },
    ],
    info: "在CPM的基础上使用哲学语料进行微调的模型，目前用于女娲、言克斯、Robbi、Ask等中文项目，RPM=Rct*CPM",
    endpoint: "http://39.101.142.52:8020/z",
    options: {
      number: { min: 1, max: 5, default: 1, type: "int" },
      length: { min: 1, max: 512, default: 100, type: "int" },
      top_p: { min: 0, max: 1, default: 0.8, type: "float" },
      top_k: { min: 0, max: 512, default: 20, type: "int" },
      temperature: { min: 0, max: 2, default: 0.8, type: "float" },
    },
  },
  {
    id: 2,
    name: "CPM",
    type: "Chinese Generate Models",
    inputParams: [
      {
        name: "prompt",
        default:
          "李逍遥，是李三思的儿子，李寒空的后人；出生于盛渔村，在李家客栈当店小二。李逍遥原是一乡下客栈店小二，天资聪颖，因一壶酒被酒剑仙传授了蜀山仙剑派剑术，在仙灵岛与赵灵儿相遇，自此经历重重磨难成长为一代旷世奇侠。悟性极高，聪明绝顶，富有强烈的正义感。少年时略滑头带点鬼灵精 。即使是小渔村中的店小二，但在客栈中英雄好汉见得多了。李逍遥的梦想就是成为“大侠”，在他心目中，侠就是像父亲李三思那样仗剑江湖、惩恶扬善，闯出一番声名，令世人尊敬的人。",
      },
    ],
    info: " 清源 CPM (Chinese Pretrained Models) 是北京智源人工智能研究院和清华大学研究团队合作开展的大规模预训练模型开源计划，清源计划是以中文为核心的大规模预训练模型。首期开源内容包括预训练中文语言模型和预训练知识表示模型，可广泛应用于中文自然语言理解、生成任务以及知识计算应用，所有模型免费向学术界和产业界开放下载，供研究使用。",
    endpoint: "https://CPM-api.dev-metaverse.fun/v1/models/kfserving-custom-model:predict",
    options: {},
  },
  {
    id: 3,
    name: "EN-Rapper",
    type: "Generate Rap Models",
    inputParams: [{ name: "prompt", default: "Love is a Crime" }],
    info: "Finetune GPT2 with rapper corpus ",
    endpoint:
      "https://enrapper-api.dev-metaverse.fun/v1/models/kfserving-custom-model:predict",
    options: {},
  },
  {
    id: 4,
    name: "ZH-Rapper",
    type: "Generate Rap Models",
    inputParams: [{ name: "prompt", default: "三里屯，夜店，美女" }],
    info: "基于微软开源框架DeepRapper训练的中文Rapper",
    endpoint:
      "https://cnrapper-api.dev-metaverse.fun/v1/models/kfserving-custom-model:predict",
    options: {},
  },
  {
    id: 5,
    name: "Dalle",
    type: "Text2Picture Models",
    inputParams: [
      { name: "prompt", default: "picture of a waterfall under the sunset" },
    ],
    info: "最近两年大名鼎鼎的DALLE模型；根据文字提示，DALL-E生成的图像可以像在现实世界中拍摄的一样。DALL-E也有能力对生成的图像中的物体进行操作和重新排列，但也能创造出一些根本不存在的东西, 预计请求时长800s",
    endpoint:
      "https://dalle-api.dev-metaverse.fun/v1/models/kfserving-custom-model:predict",
    options: {
      number: { min: 1, max: 4, default: 2, type: "int" },
    },
  },
  {
    id: 6,
    name: "GPT2-XL",
    type: "English Generate Models",
    inputParams: [{ name: "prompt", default: "Love is a Crime" }],
    info: "gpt2-large",
    endpoint:
      "https://gpt2-xl-api.dev-metaverse.fun/v1/models/kfserving-custom-model:predict",
    options: {},
  },
  {
    id: 7,
    name: "Chinese Cluecorpussmall Writing",
    type: "Chinese Generate Models",
    inputParams: [{ name: "prompt", default: "三里屯，夜店，美女" }],
    info: "UER开源的续写模型",
    endpoint:
      "https://cluecorpussmall-api.dev-metaverse.fun/v1/models/kfserving-custom-model:predict",
    options: {},
  },
  {
    id: 8,
    name: "Chinese Novel Writing",
    type: "Chinese Generate Models",
    inputParams: [
      {
        name: "prompt",
        default:
          "李逍遥，是李三思的儿子，李寒空的后人；出生于盛渔村，在李家客栈当店小二。李逍遥原是一乡下客栈店小二，天资聪颖，因一壶酒被酒剑仙传授了蜀山仙剑派剑术，在仙灵岛与赵灵儿相遇，自此经历重重磨难成长为一代旷世奇侠。悟性极高，聪明绝顶，富有强烈的正义感。少年时略滑头带点鬼灵精 。即使是小渔村中的店小二，但在客栈中英雄好汉见得多了。李逍遥的梦想就是成为“大侠”，在他心目中，侠就是像父亲李三思那样仗剑江湖、惩恶扬善，闯出一番声名，令世人尊敬的人。",
      },
    ],
    info: "中文小说续写模型",
    endpoint:
      "https://xiaoshuo-api.dev-metaverse.fun/v1/models/kfserving-custom-model:predict",
    options: {},
  },
  {
    id: 9,
    name: "Chinese Lyric Writing",
    info: "中文歌词续写模型",
    type: "Chinese Generate Models",
    inputParams: [{ name: "prompt", default: "小城里岁月流过去" }],
    endpoint:
      "https://lyric-api.dev-metaverse.fun/v1/models/kfserving-custom-model:predict",
    options: {},
  },
  {
    id: 10,
    name: "Chinese Ancient Writing",
    type: "Chinese Generate Models",
    inputParams: [{ name: "prompt", default: "发如雪" }],
    info: "中文古诗续写模型",
    endpoint:
      "https://ancient-api.dev-metaverse.fun/v1/models/kfserving-custom-model:predict",
    options: {},
  },
  {
    id: 11,
    name: "Chinese Entity Recognition",
    type: "NER Models",
    inputParams: [
      {
        name: "prompt",
        default:
          "李逍遥，是李三思的儿子，李寒空的后人；出生于盛渔村，在李家客栈当店小二。李逍遥原是一乡下客栈店小二，天资聪颖，因一壶酒被酒剑仙传授了蜀山仙剑派剑术，在仙灵岛与赵灵儿相遇，自此经历重重磨难成长为一代旷世奇侠。悟性极高，聪明绝顶，富有强烈的正义感。少年时略滑头带点鬼灵精 。即使是小渔村中的店小二，但在客栈中英雄好汉见得多了。李逍遥的梦想就是成为“大侠”，在他心目中，侠就是像父亲李三思那样仗剑江湖、惩恶扬善，闯出一番声名，令世人尊敬的人。",
      },
    ],
    info: "中文命名实体识别模型",
    endpoint:
      "https://entity-server-api.dev-metaverse.fun/v1/models/kfserving-custom-model:predict",
    options: {},
  },
  {
    id: 12,
    name: "Chinese Baike Spider",
    type: "Spider Models",
    inputParams: [{ name: "prompt", default: "元宇宙" }],
    info: "中文百科词条爬虫，输入实体或者概念名字，返回相关信息",
    endpoint:
      "https://spider-api.dev-metaverse.fun/v1/models/kfserving-custom-model:predict",
    options: {},
  },
  {
    id: 13,
    name: "Word cloud",
    type: "Word cloud tools",
    inputParams: [
      {
        name: "prompt",
        default:
          "李逍遥，是李三思的儿子，李寒空的后人；出生于盛渔村，在李家客栈当店小二。李逍遥原是一乡下客栈店小二，天资聪颖，因一壶酒被酒剑仙传授了蜀山仙剑派剑术，在仙灵岛与赵灵儿相遇，自此经历重重磨难成长为一代旷世奇侠。悟性极高，聪明绝顶，富有强烈的正义感。少年时略滑头带点鬼灵精 。即使是小渔村中的店小二，但在客栈中英雄好汉见得多了。李逍遥的梦想就是成为“大侠”，在他心目中，侠就是像父亲李三思那样仗剑江湖、惩恶扬善，闯出一番声名，令世人尊敬的人。",
      },
    ],
    info: "词云API，输入一段话，输出为词云图",
    endpoint:
      "https://word-cloud-api.dev-metaverse.fun/v1/models/kfserving-custom-model:predict",
    options: {},
  },
  {
    id: 14,
    name: "Chinese Sentence Corrector",
    type: "Sentence Correction Models",
    inputParams: [{ name: "prompt", default: "元宇宙的进头是什么" }],
    info: "中文句子纠错模型",
    endpoint:
      "https://pycorrecctor-api.dev-metaverse.fun/v1/models/kfserving-custom-model:predict",
    options: {},
  },
  {
    id: 15,
    name: "Chinese Sentence Corrector(Macbert)",
    type: "Sentence Correction Models",
    inputParams: [{ name: "prompt", default: "元宇宙的进头是什么" }],
    info: "基于Macbert的中文句子纠错模型",
    endpoint:
      "https://macbert_pycorrector-api.dev-metaverse.fun/v1/models/kfserving-custom-model:predict",
    options: {},
  },
  {
    id: 16,
    name: "English Sentence Corrector",
    type: "Sentence Correction Models",
    inputParams: [{ name: "prompt", default: "I am from Chine" }],
    info: "英文句子纠错模型",
    endpoint:
      "https://encorrect-api.dev-metaverse.fun/v1/models/kfserving-custom-model:predict",
    options: {},
  },
  {
    id: 17,
    name: "Chinese simplified2traditional",
    type: "Sentence Correction Models",
    inputParams: [{ name: "prompt", default: "元宇宙的进头是什么" }],
    info: "中文简体字转繁体字",
    endpoint:
      "https://simplified2traditional-api.dev-metaverse.fun/v1/models/kfserving-custom-model:predict",
    options: {},
  },
  {
    id: 18,
    name: "Chinese traditional2simplified",
    type: "Sentence Correction Models",
    inputParams: [{ name: "prompt", default: "元宇宙的進頭是什麼" }],
    info: "中文繁体字转简体字",
    endpoint:
      "https://traditional2simplified-api.dev-metaverse.fun/v1/models/kfserving-custom-model:predict",
    options: {},
  },
  {
    id: 19,
    name: "SIFrank",
    type: "Keyword Extractor",
    inputParams: [
      {
        name: "prompt",
        default:
          "基于深度强化学习技术的 AI 引擎。混沌球（Chaos Box）算法能够分析玩家的实时输入，并动态地生成 NPC 的交互反应与新的故事情节。在不需要任何脚本的情况下，它就能控制游戏中 NPC 的行为逻辑，并让其自发地产生非常智能的行为。",
      },
    ],
    info: "基于SIFrank的关键词抽取算法",
    endpoint:
      "https://SIFrank-api.dev-metaverse.fun/v1/models/kfserving-custom-model:predict",
    options: {},
  },
  {
    id: 20,
    name: "SIFrank-plus",
    type: "Keyword Extractor",
    inputParams: [
      {
        name: "prompt",
        default:
          "基于深度强化学习技术的 AI 引擎。混沌球（Chaos Box）算法能够分析玩家的实时输入，并动态地生成 NPC 的交互反应与新的故事情节。在不需要任何脚本的情况下，它就能控制游戏中 NPC 的行为逻辑，并让其自发地产生非常智能的行为。",
      },
    ],
    info: "基于SIFrank-plus的关键词抽取算法",
    endpoint:
      "https://SIFrank-plus-api.dev-metaverse.fun/v1/models/kfserving-custom-model:predict",
    options: {},
  },
  {
    id: 21,
    name: "TFIDF",
    type: "Keyword Extractor",
    inputParams: [
      {
        name: "prompt",
        default:
          "基于深度强化学习技术的 AI 引擎。混沌球（Chaos Box）算法能够分析玩家的实时输入，并动态地生成 NPC 的交互反应与新的故事情节。在不需要任何脚本的情况下，它就能控制游戏中 NPC 的行为逻辑，并让其自发地产生非常智能的行为。",
      },
    ],
    info: "基于TFIDF的关键词抽取算法",
    endpoint:
      "https://TFIDF-api.dev-metaverse.fun/v1/models/kfserving-custom-model:predict",
    options: {},
  },
  {
    id: 22,
    name: "TEXTrank",
    type: "Keyword Extractor",
    inputParams: [
      {
        name: "prompt",
        default:
          "基于深度强化学习技术的 AI 引擎。混沌球（Chaos Box）算法能够分析玩家的实时输入，并动态地生成 NPC 的交互反应与新的故事情节。在不需要任何脚本的情况下，它就能控制游戏中 NPC 的行为逻辑，并让其自发地产生非常智能的行为。",
      },
    ],
    info: "基于TEXTrank的关键词抽取算法",
    endpoint:
      "https://TEXTrank-api.dev-metaverse.fun/v1/models/kfserving-custom-model:predict",
    options: {},
  },
  {
    id: 23,
    name: "blender-9B",
    type: "Generate Models",
    inputParams: [{ name: "prompt", default: "I am from china" }],
    info: "Model released by ParlAI. Building open-domain chatbots is a challenging area for machine learning research. While prior work has shown that scaling neural models in the number of parameters and the size of the data they are trained on gives improved results, we show that other ingredients are important for a high-performing chatbot. Good conversation requires a number of skills that an expert conversationalist blends in a seamless way: providing engaging talking points and listening to their partners, both asking and answering questions, and displaying knowledge, empathy and personality appropriately, depending on the situation. ",
    endpoint:
      "https://blender-9B-api.dev-metaverse.fun/v1/models/kfserving-custom-model:predict",
    options: {},
  },
  {
    id: 24,
    name: "blender-3B",
    type: "Generate Models",
    inputParams: [{ name: "prompt", default: "I am from china" }],
    info: "Model released by ParlAI. Building open-domain chatbots is a challenging area for machine learning research. While prior work has shown that scaling neural models in the number of parameters and the size of the data they are trained on gives improved results, we show that other ingredients are important for a high-performing chatbot. Good conversation requires a number of skills that an expert conversationalist blends in a seamless way: providing engaging talking points and listening to their partners, both asking and answering questions, and displaying knowledge, empathy and personality appropriately, depending on the situation. ",
    endpoint:
      "https://blender-3B-api.dev-metaverse.fun/v1/models/kfserving-custom-model:predict",
    options: {},
  },
  {
    id: 25,
    name: "blender-1B",
    type: "Generate Models",
    inputParams: [{ name: "prompt", default: "I am from china" }],
    info: "Model released by ParlAI. Building open-domain chatbots is a challenging area for machine learning research. While prior work has shown that scaling neural models in the number of parameters and the size of the data they are trained on gives improved results, we show that other ingredients are important for a high-performing chatbot. Good conversation requires a number of skills that an expert conversationalist blends in a seamless way: providing engaging talking points and listening to their partners, both asking and answering questions, and displaying knowledge, empathy and personality appropriately, depending on the situation. ",
    endpoint:
      "https://blender-1B-api.dev-metaverse.fun/v1/models/kfserving-custom-model:predict",
    options: {},
  },
  {
    id: 26,
    name: "blender-440M",
    type: "Generate Models",
    inputParams: [{ name: "prompt", default: "I am from china" }],
    info: "Model released by ParlAI. Building open-domain chatbots is a challenging area for machine learning research. While prior work has shown that scaling neural models in the number of parameters and the size of the data they are trained on gives improved results, we show that other ingredients are important for a high-performing chatbot. Good conversation requires a number of skills that an expert conversationalist blends in a seamless way: providing engaging talking points and listening to their partners, both asking and answering questions, and displaying knowledge, empathy and personality appropriately, depending on the situation. ",
    endpoint:
      "https://blender-440M-api.dev-metaverse.fun/v1/models/kfserving-custom-model:predict",
    options: {},
  },
  {
    id: 27,
    name: "Context-QA-Macbert",
    type: "Context-QA Models",
    inputParams: [
      { name: "question", default: "我来自哪里" },
      { name: "context", default: "我来自中国,我来自北京" },
    ],
    info: "Chinese Context-QA Models",
    endpoint:
      "https://context-qa-macbert-api.dev-metaverse.fun/v1/models/kfserving-custom-model:predict",
    options: {},
  },
  {
    id: 28,
    name: "Context-QA-Roberta-Large",
    type: "Context-QA Models",
    inputParams: [
      { name: "question", default: "我来自哪里" },
      { name: "context", default: "我来自中国,我来自北京" },
    ],
    info: "Chinese Context-QA Models",
    endpoint:
      "https://context-qa-roberta-large-api.dev-metaverse.fun/v1/models/kfserving-custom-model:predict",
    options: {},
  },
  {
    id: 29,
    name: "Context-QA-Roberta-Base",
    type: "Context-QA Models",
    inputParams: [
      { name: "question", default: "我来自哪里" },
      { name: "context", default: "我来自中国,我来自北京" },
    ],
    info: "Chinese Context-QA Models",
    endpoint:
      "https://context-qa-roberta-base-api.dev-metaverse.fun/v1/models/kfserving-custom-model:predict",
    options: {},
  },
  {
    id: 30,
    name: "gpt-neo-20b",
    type: "English Generate Models",
    inputParams: [{ name: "prompt", default: "Love is a Crime" }],
    info: "NeoX 20B is the latest model produced by EleutherAI and the biggest open sourced Language Model.",
    endpoint:
      "https://gpt-neo-20b-api.dev-metaverse.fun/v1/models/kfserving-custom-model:predict",
    options: {
      max_tokens: { min: 1, max: 1024, default: 200, type: "int" },
      top_k: { min: 1, max: 100, default: 20, type: "int" },
      frequency_penalty: { min: 0, max: 1, default: 0.3, type: "float" },
      top_p: { min: 0, max: 1, default: 0.8, type: "float" },
      temperature: { min: 0, max: 2, default: 0.8, type: "float" },
    },
  },
  {
    id: 31,
    name: "gpt-neo-2-7b",
    type: "English Generate Models",
    inputParams: [{ name: "prompt", default: "Love is a Crime" }],
    info: "English Generate Models",
    endpoint:
      "https://gpt-neo-20b-api.dev-metaverse.fun/v1/models/kfserving-custom-model:predict",
    options: {
      max_tokens: { min: 1, max: 1024, default: 200, type: "int" },
      top_k: { min: 1, max: 100, default: 20, type: "int" },
      frequency_penalty: { min: 0, max: 1, default: 0.3, type: "float" },
      top_p: { min: 0, max: 1, default: 0.8, type: "float" },
      temperature: { min: 0, max: 2, default: 0.8, type: "float" },
    },
  },
  {
    id: 32,
    name: "gpt-neo-1-3b",
    type: "English Generate Models",
    inputParams: [{ name: "prompt", default: "Love is a Crime" }],
    info: "English Generate Models",
    endpoint:
      "https://gpt-neo-1-3b-api.dev-metaverse.fun/v1/models/kfserving-custom-model:predict",
    options: {
      max_tokens: { min: 1, max: 1024, default: 200, type: "int" },
      top_k: { min: 1, max: 100, default: 20, type: "int" },
      frequency_penalty: { min: 0, max: 1, default: 0.3, type: "float" },
      top_p: { min: 0, max: 1, default: 0.8, type: "float" },
      temperature: { min: 0, max: 2, default: 0.8, type: "float" },
    },
  },
  {
    id: 33,
    name: "gpt-neo-125m",
    type: "English Generate Models",
    inputParams: [{ name: "prompt", default: "Love is a Crime" }],
    info: "English Generate Models",
    endpoint:
      "https://gpt-neo-125m-api.dev-metaverse.fun/v1/models/kfserving-custom-model:predict",
    options: {
      max_tokens: { min: 1, max: 1024, default: 200, type: "int" },
      top_k: { min: 1, max: 100, default: 20, type: "int" },
      frequency_penalty: { min: 0, max: 1, default: 0.3, type: "float" },
      top_p: { min: 0, max: 1, default: 0.8, type: "float" },
      temperature: { min: 0, max: 2, default: 0.8, type: "float" },
    },
  },
  {
    id: 34,
    name: "gpt-j-6b",
    type: "English Generate Models",
    inputParams: [{ name: "prompt", default: "Love is a Crime" }],
    info: "English Generate Models",
    endpoint:
      "https://gpt-j-6b-api.dev-metaverse.fun/v1/models/kfserving-custom-model:predict",
    options: {
      max_tokens: { min: 1, max: 1024, default: 200, type: "int" },
      top_k: { min: 1, max: 100, default: 20, type: "int" },
      frequency_penalty: { min: 0, max: 1, default: 0.3, type: "float" },
      top_p: { min: 0, max: 1, default: 0.8, type: "float" },
      temperature: { min: 0, max: 2, default: 0.8, type: "float" },
    },
  },
  {
    id: 35,
    name: "fairseq-13b",
    type: "English Generate Models",
    inputParams: [{ name: "prompt", default: "Love is a Crime" }],
    info: "English Generate Models",
    endpoint:
      "https://fairseq-13b-api.dev-metaverse.fun/v1/models/kfserving-custom-model:predict",
    options: {
      max_tokens: { min: 1, max: 1024, default: 200, type: "int" },
      top_k: { min: 1, max: 100, default: 20, type: "int" },
      frequency_penalty: { min: 0, max: 1, default: 0.3, type: "float" },
      top_p: { min: 0, max: 1, default: 0.8, type: "float" },
      temperature: { min: 0, max: 2, default: 0.8, type: "float" },
    },
  },
  {
    id: 36,
    name: "fairseq-6-7b",
    type: "English Generate Models",
    inputParams: [{ name: "prompt", default: "Love is a Crime" }],
    info: "English Generate Models",
    endpoint:
      "https://fairseq-6-7b-api.dev-metaverse.fun/v1/models/kfserving-custom-model:predict",
    options: {
      max_tokens: { min: 1, max: 1024, default: 200, type: "int" },
      top_k: { min: 1, max: 100, default: 20, type: "int" },
      frequency_penalty: { min: 0, max: 1, default: 0.3, type: "float" },
      top_p: { min: 0, max: 1, default: 0.8, type: "float" },
      temperature: { min: 0, max: 2, default: 0.8, type: "float" },
    },
  },
  {
    id: 37,
    name: "fairseq-2-7b",
    type: "English Generate Models",
    inputParams: [{ name: "prompt", default: "Love is a Crime" }],
    info: "English Generate Models",
    endpoint:
      "https://fairseq-2-7b-api.dev-metaverse.fun/v1/models/kfserving-custom-model:predict",
    options: {
      max_tokens: { min: 1, max: 1024, default: 200, type: "int" },
      top_k: { min: 1, max: 100, default: 20, type: "int" },
      frequency_penalty: { min: 0, max: 1, default: 0.3, type: "float" },
      top_p: { min: 0, max: 1, default: 0.8, type: "float" },
      temperature: { min: 0, max: 2, default: 0.8, type: "float" },
    },
  },
  {
    id: 38,
    name: "fairseq-1-3b",
    type: "English Generate Models",
    inputParams: [{ name: "prompt", default: "Love is a Crime" }],
    info: "English Generate Models",
    endpoint:
      "https://fairseq-1-3b-api.dev-metaverse.fun/v1/models/kfserving-custom-model:predict",
    options: {
      max_tokens: { min: 1, max: 1024, default: 200, type: "int" },
      top_k: { min: 1, max: 100, default: 20, type: "int" },
      frequency_penalty: { min: 0, max: 1, default: 0.3, type: "float" },
      top_p: { min: 0, max: 1, default: 0.8, type: "float" },
      temperature: { min: 0, max: 2, default: 0.8, type: "float" },
    },
  },
  {
    id: 39,
    name: "fairseq-125m",
    type: "English Generate Models",
    inputParams: [{ name: "prompt", default: "Love is a Crime" }],
    info: "English Generate Models",
    endpoint:
      "https://fairseq-125m-api.dev-metaverse.fun/v1/models/kfserving-custom-model:predict",
    options: {
      max_tokens: { min: 1, max: 1024, default: 200, type: "int" },
      top_k: { min: 1, max: 100, default: 20, type: "int" },
      frequency_penalty: { min: 0, max: 1, default: 0.3, type: "float" },
      top_p: { min: 0, max: 1, default: 0.8, type: "float" },
      temperature: { min: 0, max: 2, default: 0.8, type: "float" },
    },
  },
  {
    id: 40,
    name: "InstructGPT3-davinci",
    type: "English Generate Models",
    inputParams: [{ name: "prompt", default: "Love is a Crime" }],
    info: "Compared to GPT-3, InstructGPT produces fewer imitative falsehoods (according to TruthfulQA) and are less toxic (according to RealToxicityPrompts).",
    endpoint:
      "https://instructgpt3-davinci-api.dev-metaverse.fun/v1/models/kfserving-custom-model:predict",
    options: {
      max_tokens: { min: 1, max: 1024, default: 200, type: "int" },
      frequency_penalty: { min: 0, max: 1, default: 0.3, type: "float" },
      top_p: { min: 0, max: 1, default: 0.8, type: "float" },
      temperature: { min: 0, max: 2, default: 0.8, type: "float" },
    },
  },
  {
    id: 41,
    name: "InstructGPT3-curie",
    type: "English Generate Models",
    inputParams: [{ name: "prompt", default: "Love is a Crime" }],
    info: "Compared to GPT-3, InstructGPT produces fewer imitative falsehoods (according to TruthfulQA) and are less toxic (according to RealToxicityPrompts).",
    endpoint:
      "https://instructgpt3-curie-api.dev-metaverse.fun/v1/models/kfserving-custom-model:predict",
    options: {
      max_tokens: { min: 1, max: 1024, default: 200, type: "int" },
      frequency_penalty: { min: 0, max: 1, default: 0.3, type: "float" },
      top_p: { min: 0, max: 1, default: 0.8, type: "float" },
      temperature: { min: 0, max: 2, default: 0.8, type: "float" },
    },
  },
  {
    id: 42,
    name: "InstructGPT3-babbage",
    type: "English Generate Models",
    inputParams: [{ name: "prompt", default: "Love is a Crime" }],
    info: "Compared to GPT-3, InstructGPT produces fewer imitative falsehoods (according to TruthfulQA) and are less toxic (according to RealToxicityPrompts).",
    endpoint:
      "https://instructgpt3-babbage-api.dev-metaverse.fun/v1/models/kfserving-custom-model:predict",
    options: {
      max_tokens: { min: 1, max: 1024, default: 200, type: "int" },
      frequency_penalty: { min: 0, max: 1, default: 0.3, type: "float" },
      top_p: { min: 0, max: 1, default: 0.8, type: "float" },
      temperature: { min: 0, max: 2, default: 0.8, type: "float" },
    },
  },
  {
    id: 43,
    name: "InstructGPT3-ada",
    type: "English Generate Models",
    inputParams: [{ name: "prompt", default: "Love is a Crime" }],
    info: "Compared to GPT-3, InstructGPT produces fewer imitative falsehoods (according to TruthfulQA) and are less toxic (according to RealToxicityPrompts).",
    endpoint:
      "https://instructgpt3-ada-api.dev-metaverse.fun/v1/models/kfserving-custom-model:predict",
    password: "thisisrct",
    options: {
      max_tokens: { min: 1, max: 1024, default: 200, type: "int" },
      frequency_penalty: { min: 0, max: 1, default: 0.3, type: "float" },
      top_p: { min: 0, max: 1, default: 0.8, type: "float" },
      temperature: { min: 0, max: 2, default: 0.8, type: "float" },
    },
  },
  {
    id: 44,
    name: "GPT3-davinci",
    type: "English Generate Models",
    inputParams: [{ name: "prompt", default: "Love is a Crime" }],
    info: "Generative Pre-trained Transformer 3 (GPT-3) is an autoregressive language model that uses deep learning to produce human-like text.",
    endpoint:
      "https://gpt3-davinci-api.dev-metaverse.fun/v1/models/kfserving-custom-model:predict",
    password: "thisisrct",
    options: {
      max_tokens: { min: 1, max: 1024, default: 200, type: "int" },
      frequency_penalty: { min: 0, max: 1, default: 0.3, type: "float" },
      top_p: { min: 0, max: 1, default: 0.8, type: "float" },
      temperature: { min: 0, max: 2, default: 0.8, type: "float" },
    },
  },
  {
    id: 45,
    name: "GPT3-curie",
    type: "English Generate Models",
    inputParams: [{ name: "prompt", default: "Love is a Crime" }],
    info: "Generative Pre-trained Transformer 3 (GPT-3) is an autoregressive language model that uses deep learning to produce human-like text.",
    endpoint:
      "https://gpt3-curie-api.dev-metaverse.fun/v1/models/kfserving-custom-model:predict",
    password: "thisisrct",
    options: {
      max_tokens: { min: 1, max: 1024, default: 200, type: "int" },
      frequency_penalty: { min: 0, max: 1, default: 0.3, type: "float" },
      top_p: { min: 0, max: 1, default: 0.8, type: "float" },
      temperature: { min: 0, max: 2, default: 0.8, type: "float" },
    },
  },
  {
    id: 46,
    name: "GPT3-babbage",
    type: "English Generate Models",
    inputParams: [{ name: "prompt", default: "Love is a Crime" }],
    info: "Generative Pre-trained Transformer 3 (GPT-3) is an autoregressive language model that uses deep learning to produce human-like text.",
    endpoint:
      "https://gpt3-babbage-api.dev-metaverse.fun/v1/models/kfserving-custom-model:predict",
    password: "thisisrct",
    options: {
      max_tokens: { min: 1, max: 1024, default: 200, type: "int" },
      frequency_penalty: { min: 0, max: 1, default: 0.3, type: "float" },
      top_p: { min: 0, max: 1, default: 0.8, type: "float" },
      temperature: { min: 0, max: 2, default: 0.8, type: "float" },
    },
  },
  {
    id: 47,
    name: "GPT3-ada",
    type: "English Generate Models",
    inputParams: [{ name: "prompt", default: "Love is a Crime" }],
    info: "Generative Pre-trained Transformer 3 (GPT-3) is an autoregressive language model that uses deep learning to produce human-like text.",
    endpoint:
      "https://gpt3-ada-api.dev-metaverse.fun/v1/models/kfserving-custom-model:predict",
    password: "thisisrct",
    options: {
      max_tokens: { min: 1, max: 1024, default: 200, type: "int" },
      frequency_penalty: { min: 0, max: 1, default: 0.3, type: "float" },
      top_p: { min: 0, max: 1, default: 0.8, type: "float" },
      temperature: { min: 0, max: 2, default: 0.8, type: "float" },
    },
  },
  {
    id: 48,
    name: "Stable diffusion",
    type: "Word cloud tools",
    inputParams: [{ name: "prompt", default: "A small raccoon is in love with a penguin on the beach" }],
    info: "Stable diffusion",
    endpoint:
      "http://39.99.138.47:8100/create_task",
    options: {
    },
  },
];
