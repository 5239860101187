<template>
  <footer>
    <span class="text"> Mochi - 墨池 V.0.1 </span>
  </footer>
</template>

<script>
export default {
  name: "Footer",
};
</script>

<style scoped>
.text {
  font-size: 0.9rem;
  color: var(--text-secondary-color);
}
</style>
