<template>
  <nav>
    <img class="logo" alt="rct AI logo" src="../assets/rct-logo.png" />
    <button
      role="button"
      class="models-button"
      aria-label="menu button"
      @click="handleOpenModal"
    >
      Models
    </button>
  </nav>
</template>

<script>
export default {
  name: "Navbar",

  methods: {
    handleOpenModal() {
      this.$emit("toggle-dialog", true);
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
nav {
  width: 100%;
  margin: 0 auto;
  height: 4rem;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 0;
}

.logo {
  height: auto;
  width: 10rem;
}
.models-button {
  text-transform: uppercase;
  padding: 0.5rem;
  color: var(--text-secondary-color);
  border: 1px solid var(--text-secondary-color);
  cursor: pointer;
  opacity: 0.9;
  transition: all 0.4s ease;
  background-color: var(--grey-primary-color);
  border-radius: 2px;
}
.models-button:hover {
  opacity: 1;
}
@media screen and (max-width: 570px) {
  .logo {
    height: auto;
    width: 8rem;
  }
}
</style>
